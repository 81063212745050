import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IPrice } from "../../@types/graphql";
import { FC, memo, useMemo } from "react";

import "../../styles/components/tables.css";
import "../../styles/pages/pricesPage.css";
import { PriceCell } from "./priceCell";
import { AdditionalCell } from "./additionalCell";
import { PriceTableHeader } from "./priceTableHeader";
import { PricesActions } from "./pricesActions";
import { CommentCell } from "./commentCell";

const columnHelper = createColumnHelper<IPrice>();

export const PricesTable: FC<{ prices: IPrice[] }> = memo(({ prices }) => {
  const pricesColumns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        header: "ID",
        size: 20,
      }),
      columnHelper.accessor("sku", {
        id: "sku",
        header: "SKU",
        size: 20,
      }),
      columnHelper.accessor("price", {
        id: "price",
        header: "Начальная цена",
        cell: PriceCell,
      }),
      columnHelper.display({
        id: "additional",
        header: "Изменение цены относительно количества",
        size: 400,
        cell: AdditionalCell,
      }),
      columnHelper.accessor("comment", {
        id: "comment",
        header: "Комментарий",
        size: 400,
        cell: CommentCell,
      }),
      columnHelper.display({
        id: "actions",
        cell: PricesActions,
      }),
    ],
    [],
  );

  const table = useReactTable({
    data: prices,
    columns: pricesColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!prices?.length) return null;
  return (
    <>
      <PriceTableHeader />
      <div className="table-default-wrapper">
        <table className="table-default price-table">
          <thead className="table-default-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ width: header.getSize() }}
                    colSpan={header.colSpan}
                    className={header.id}
                  >
                    <span className="table-default-sorting-item">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={cell.column.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
});

PricesTable.displayName = "PricesTable";
